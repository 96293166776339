import { render, staticRenderFns } from "./InvoiceEdit.vue?vue&type=template&id=478b5ccc"
import script from "./InvoiceEdit.vue?vue&type=script&lang=js"
export * from "./InvoiceEdit.vue?vue&type=script&lang=js"
import style0 from "./InvoiceEdit.vue?vue&type=style&index=0&id=478b5ccc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports